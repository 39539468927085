import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PeaksuiteToolsLogos = () => (
  <section className="py-md-5">
    <div className="container">
      <div className="row">
        {/* <div className="col-lg-4" style={{ padding: "2rem" }}>
                  <a href="#peak1">
                    {" "}
                    <StaticImage
                      placeholder="blurred"
                      imgClassName="img-fluid w-100"
                      src="../../../static/assets/peaksuite-Chat Tool.png"
                      alt="peakSuite Chat Tool"
                      layout="constrained"
                      loading="lazy"
                    />
                  </a>
                </div> */}

        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak2">
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/peaksuite-DU.png"
              alt="peakSuite Document Generator"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak3">
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/PS_multi-file-uploader.png"
              alt="peakSuite Multi File Uploader"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak4">
            {" "}
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/PS_compass.png"
              alt="peakSuite Compass"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        {/* <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak5">
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/peaksuite-Calendar.png"
              alt="peakSuite Calendar"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak6">
            {" "}
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/PS-Pivot-Table.png"
              alt="peakSuite Pivot Table"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak7">
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/PS-echo.png"
              alt="peakSuite Echo"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak8">
            {" "}
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/PS_trailhead.png"
              alt="peakSuite Trailhead"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div>
        <div className="col-lg-4" style={{ padding: "2rem" }}>
          <a href="#peak9">
            <StaticImage
              placeholder="blurred"
              imgClassName="img-fluid w-100"
              src="../../../static/assets/productLogos/basecamp.png"
              alt="peakSuite Pivot-table"
              layout="constrained"
              loading="lazy"
            />
          </a>
        </div> */}
      </div>
    </div>
  </section>
)

export default PeaksuiteToolsLogos
